import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Text, Title } from './Core'
import { Col, Row } from 'react-bootstrap'
import loadable from '@loadable/component'
import theme from '../utils/theme'
import {
  fetchExchMarketData,
  setFeesTotalCost,
  setFeesOrderSide
} from '../store/actions/exchDataActions'
import { isDecimal } from '../utils/helperFn'
import { left, verticalAlign } from 'styled-system'

const Select = loadable(() => import('react-select'))

const FeesCalculator = props => {
  const { t, ready } = useTranslation(['tables'], { useSuspense: false })
  const dispatch = useDispatch()
  const userSettings = useSelector(state => state.user.user_settings)
  const authIds = useSelector(state => state.user.authIds)
  // const authExchIds = useSelector(state => state.user.authExchIds)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const exchMarketData = useSelector(state => state.exchData.exchMarketData)
  const exchMarketsArray = []
  // const baseCurrExchRate = useSelector(state => state.markets.baseCurrExchRate)


  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const [marketsLoaded, setMarketsLoaded] = useState(false)
  const [priceInput, setPriceInput] = useState(0)
  const [orderMarket, setOrderMarket] = useState('')
  const [quoteCurr, setQuoteCurr] = useState('USD')
  const [baseCurr, setBaseCurr] = useState('BTC')
  const [amountInput, setAmountInput] = useState(1)
  const [totalInput, setTotalInput] = useState(0)
  const [lastTradePrice, setLastTradedPrice] = useState(0)

    if (exchMarketData.length > 0) {
      for (const mkt in exchMarketData) {
        exchMarketsArray.push(
          { value: exchMarketData[mkt].marketName, label: exchMarketData[mkt].marketName }
        )
      }
    }
  
  useEffect(() => {
    if (typeof exchMarketsArray[0] !== 'undefined') {
      setMarketsLoaded(true)
      setPriceInput(exchMarketData[0].lastTradePrice)
      setLastTradedPrice(exchMarketData[0].lastTradePrice)
    }
    
  }, [exchMarketsArray.length])

  

  useEffect(() => {
    dispatch(
      fetchExchMarketData(
        null,
        'lightspeed-crypto',
        'Favorites',
        'desc',
        50,
        1,
        'SevenDay'
      )
    )
    const interval = setInterval(() => {
      dispatch(
        fetchExchMarketData(
          null,
          'lightspeed-crypto',
          'Favorites',
          'desc',
          50,
          1,
          'SevenDay'
        )
      )
    }, 90000)
    return () => clearInterval(interval)
  }, [])

 
  useEffect(() => {
    // setAmountInput(totalInput / priceInput)
    setTotalInput(amountInput * priceInput)
    dispatch(setFeesTotalCost(parseFloat((amountInput * priceInput))))
  }, [priceInput])

  const handleOrderSideChange = value => {
    if (value === 2) {
      setOrderSide(value)
      dispatch(setFeesOrderSide(value))
      setPriceInput(parseFloat(priceInput - (priceInput * 0.0015)).toFixed(2))
    }
    if (value === 1) {
      setOrderSide(value)
      dispatch(setFeesOrderSide(value))
      setPriceInput(lastTradePrice)
    }
  }

  const handleAmountChange = async value => {
    if (!isDecimal(value)) return

    await setAmountInput(value)
    // await setUserCurrInput(Number(value * quoteCurrExchRate).toFixed(2))
    await setTotalInput(value * priceInput)
    await dispatch(setFeesTotalCost(parseFloat(value * priceInput)))
  }

  const handleTotalChange = async value => {
    if (!isDecimal(value)) return
    await dispatch(setFeesTotalCost(parseFloat(value)))
    await setTotalInput(value)
    // await setUserCurrInput(Number(value * quoteCurrExchRate).toFixed(2))
    await setAmountInput(value / priceInput)
  }
  
  

  const handleMarketSelector = value => {
    setOrderMarket(value)
    for (const mkt in exchMarketData) {
      if (exchMarketData[mkt].marketName === value) {
        setPriceInput(exchMarketData[mkt].lastTradePrice)
        setBaseCurr(exchMarketData[mkt].baseCurrencyCode)
        setQuoteCurr(exchMarketData[mkt].quoteCurrencyCode)
        setLastTradedPrice(exchMarketData[mkt].lastTradePrice)
      } 
    }
  }

  const orderSideOptions = [
    { value: 1, label: 'Buy' },
    { value: 2, label: 'Sell' },
  ]
 
  const [orderSide, setOrderSide] = useState(1)
  const getCustomStyles = theme => {
    return {
      option: (provided, state) => ({
        ...provided,
        color: userDarkMode ? '#FAFAFA' : 'rgba(0, 0, 0, 0.56)',
        textAling: 'Left',
        backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
        fontSize: 16,
        paddingRight: 20,
        paddingLeft: 20,
        textAlign: 'Left',
        zIndex: 1,
        '&:hover': {
          backgroundColor: userDarkMode
            ? 'rgb(43, 47, 54)'
            : 'rgba(0, 0, 0, 0.04)',
        },
      }),
      control: (provided, state) => {
        return {
          ...provided,
          backgroundColor: userDarkMode
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)',
          border: `0px solid transparent !important`,
          paddingRight: 10,
          paddingLeft: 10,
          zIndex: 0,
          fontSize: 16,
          width: '160px',
          outline: 'none',
          height: 44,
          minHeight: 20,
          borderRadius: 5,
          boxShadow: 'none',
          color: userDarkMode
            ? 'rgba(255, 255, 255, 0.56)'
            : 'rgba(0, 0, 0, 0.56)',
          '&:hover': {
            backgroundColor: userDarkMode
              ? 'rgb(43, 47, 54)'
              : 'rgba(0, 0, 0, 0.04)',
          },
        }
      },
      menu: provided => ({
        ...provided,
        borderRadius: 12,
        top: 38,
      }),
      dropdownIndicator: base => ({
        ...base,
        color: userDarkMode ? '#FAFAFA' : 'black' // Custom colour
      }),
      menuList: provided => ({
        ...provided,
        backgroundColor: userDarkMode ? '#191B20' : '#FAFAFA',
        borderRadius: 10,
      }),
      singleValue: (provided, state) => {
        const color = 'inherit'
        const opacity = state.isDisabled ? 0.5 : 1
        const transition = 'opacity 300ms'

        return { ...provided, color, opacity, transition }
      },
    }
  }
  const authOptions = []

  if (authIds.length > 0) {
    for (const id in authIds) {
      authOptions.push({
        value: authIds[id].authId,
        label: authIds[id].authNickname,
      })
    }
  }

  return ready && (
    
    <div
      style={{
        padding: 16,
        paddingTop: 16,
        paddingLeft: 16,
        borderBottom: userDarkMode ? '1px solid rgba(255,255,255,0.15)' : '1px solid rgba(0,0,0,0.15)'
      }}
    >
      <Title color={userDarkMode ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)'} className="mb-3" variant="card">
        {t('compare')}
      </Title>
      <Row >
        <Col xs="auto" className="mb-3 mb-xl-0">
          <Select
            isSearchable={false}
            options={orderSideOptions}
            styles={getCustomStyles(theme)}
            borderRadius={0}
            defaultValue={orderSideOptions[0]}
            onChange={option => handleOrderSideChange(option.value)}
            components={{ IndicatorSeparator: () => null }}
          />
        </Col>
        <Col xs="auto" className="mb-3 mb-xl-0">
          {
            marketsLoaded &&
            <Select
              isSearchable={false}
              options={exchMarketsArray}
              styles={getCustomStyles(theme)}
              borderRadius={0}
              defaultValue={
                typeof exchMarketsArray[0] !== 'undefined' ?
                exchMarketsArray[0] :
                {value: 'BTC/USD', label: 'BTC/USD'}
              }
              onChange={option => handleMarketSelector(option.value)}
              components={{ IndicatorSeparator: () => null }}
            />
          }
        </Col>
       
          {/* <div className="mr-4 mt-3 mt-sm-0">
            <input
              style={{
                height: '44px',
                borderRadius: 6,
                width: '150px',
                backgroundColor: userDarkMode
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)',
                color: userDarkMode ? '#FAFAFA' : 'black',
              }}
              value={amountInput}
              onChange={e => handleAmountChange(e.target.value)}
              type="text"
              className="form-control"
              placeholder={t('enterAmount')}
              //   onKeyPress={e => handleKeypress(e)}
            />
            <Text className="mt-1" variant="small" color={userDarkMode ? '#FAFAFA' : 'black'}>
              Amount {''} {baseCurr}
            </Text>
          </div> */}
          <Col xl lg="12">
            {
              marketsLoaded &&
              <div style={{width: '100%'}}>
                <Text variant="small" style={{fontWeight:'400', position: 'absolute', padding: '10px 16px', color: userDarkMode ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)'}}>
                  {baseCurr}
                </Text>
                <input
                  id="totalInput"
                  style={{
                    height: '44px',
                    borderRadius: 6,
                    width: '100%',
                    paddingRight: '16px',
                    backgroundColor: userDarkMode
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(0, 0, 0, 0.1)',
                    color: userDarkMode ? '#FAFAFA' : 'black',
                    textAlign: 'end',
                    borderColor: 'transparent',
                    borderWidth: '0'
                    // display: 'flex'
                  }}
                  value={amountInput}
                  onChange={e => handleAmountChange(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder={orderSide === 1 ? `e.g. 0.5` : `e.g. 0.5`}
                  //   onKeyPress={e => handleKeypress(e)}
                />
              </div>
            }
          </Col>
          {/* <Col className="mt-3 mt-xl-0" xl="4" lg="12"> */}
            {/* {
              marketsLoaded &&
              <div style={{display: 'flex'}} >
                <input
                
                  id="totalInput"
                  style={{
                    height: '44px',
                    borderRadius: 6,
                    width: '200px',
                    backgroundColor: userDarkMode
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'rgba(0, 0, 0, 0.1)',
                    color: userDarkMode ? '#FAFAFA' : 'black',
                    textAlign: 'end',
                    borderColor: 'transparent',
                    // display: 'flex'
                  }}
                  value={totalInput}
                  onChange={e => handleTotalChange(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder={orderSide === 1 ? `${t('landing.totalCost')}` : `${t('totalProceeds')}`}
                  //   onKeyPress={e => handleKeypress(e)}
                />
                <Text variant="small" className="mt-2" style={{position:'relative', left: -190, top: 2, color: userDarkMode ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)'}}>
                  {quoteCurr}
                </Text>
                <Text variant="small" className="mt-2" style={{position:'relative', left: -115, top: 2, color: userDarkMode ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)'}}>$</Text>
              </div>
            } */}
            
            {/* <Text className="mt-1" variant="small" color={userDarkMode ? '#FAFAFA' : 'black'}>
            {orderSide === 1 ? `${t('landing.totalCost')}` : `${t('totalProceeds')}`} {' '} {quoteCurr}
            </Text> */}
          {/* </Col> */}
      </Row>
      <Row className="ml-1 mr-3 mt-2" style={{justifyContent: 'space-between'}}>
        <Text variant="small" color={userDarkMode ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)'}>
          {t('lsPrice')} {' '} <strong>{quoteCurr} {' '} {priceInput.toLocaleString(undefined)}</strong>
        </Text>
        {/* <Text style={{fontSize: '13px'}} variant="small" color={userDarkMode ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)'}>
          {t('amount')} {' '} {amountInput < 1 ? amountInput.toFixed(5) : amountInput.toFixed(2)} {' '} {baseCurr}
        </Text> */}
      </Row>
      
    </div>
  )
}

export default FeesCalculator